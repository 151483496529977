<template>
  <div class="row" id="my-container">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected"/>
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <template v-if="driverList.length>0">
              <div class="mt-0">
                <div class="row">
                  <div v-for="(driver, index) in driverList" class="col-md-3">
                    <div class="card">
                      <div class="card-body">
                        <div class="row" style="cursor:pointer;" :key="driver['id']">
                          <div class="col-auto">
                            <b-avatar size="md" variant="dark" class="text-light"
                            ><span class="">{{ driver.name | initials }}</span></b-avatar
                            >
                          </div>
                          <div class="col">
                            <h5 class="mt-0 mb-0 font-14 line-ellipsis">
                              {{ driver.name }} {{ driver['other_names'] }}
                            </h5>
                            <div><span class="badge badge-success">{{ driver['driver']['dossier'] }}</span></div>
                            <h6>{{ driver['prospective_driver']['service_type'] | serviceType }}</h6>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <hr>
              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <h4 class="card-title">Filter By</h4>
                    <select class="form-control" v-model="selectedOption" @change="selectFilterCard">
                      <option value="filter-by-date-range">Date Range</option>
                      <option value="filter-by-weeks">Weeks</option>
                      <option value="filter-by-periods">Periods, Yearly, Quarterly</option>
                    </select>
                  </div>
                </div>
              </div>

              <b-card v-if="selectedOption==='filter-by-date-range'">

                <h4 class="card-title">Filter by date range</h4>

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Select date</label>
                      <date-picker v-model="date_range" valueType="format" @clear='removeDate'
                                   @change="dateRangeChanged" range append-to-body lang="en" confirm></date-picker>
                    </div>
                  </div>

                </div>
              </b-card>


              <b-card v-if="selectedOption==='filter-by-weeks'">

                <h4 class="card-title">Filter by weeks</h4>

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>From</label>
                      <input type="week" class="form-control" v-model="startWeek" @change="weekChanged">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>To</label>
                      <input type="week" class="form-control" v-model="endWeek" @change="weekChanged">
                    </div>
                  </div>

                </div>
              </b-card>
              <b-card v-if="selectedOption==='filter-by-periods'">
                <h4 class="card-title">Filter by periods, yearly, quarterly</h4>
                <div class="row">

                  <div class="col-md-12">
                    <div>
                      <b-form inline>
                        <select class="form-control mr-2" v-model="selectedYear">
                          <option :value="ys" v-for="ys in years">{{ ys }}</option>
                        </select>

                        <select class="form-control mr-2" v-model="filterSelected" @change="onFilterPeriodsChanged">
                          <option v-for="option in filterOptions" :value="option.value"> {{ option.text }}</option>
                        </select>
                      </b-form>
                    </div>
                  </div>


                  <!--                  <div class="col-md-9">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <label><span class="mr-2">Enter periods</span>-->
                  <!--&lt;!&ndash;                        <a href="#" class="mr-2 text-info" @click.prevent="addAllPeriods" >add all periods</a>&ndash;&gt;-->
                  <!--&lt;!&ndash;                        <a href="#"  @click.prevent="removeAllPeriods" class="mr-2 text-danger">remove all periods</a>&ndash;&gt;-->
                  <!--                      </label>-->


                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>

                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="">Selected periods</label>
                    <b-form-tags
                        input-id="tags-pills"
                        v-model="periods"
                        tag-variant="primary"
                        class="mr-2"
                        tag-pills
                        :tag-validator="periodTagValidator"
                        size="lg"
                        separator=" "
                        placeholder="Periods"
                    ></b-form-tags>
                  </div>
                  <div class="col-md-3">
                    <label class="">Download Option</label>
                    <select class="form-control" v-model="downloadOption">
                      <option value="single"> Single Periods</option>
                      <option value="merge"> Merge Periods</option>
                    </select>
                  </div>
                </div>

              </b-card>

              <b-card>
                <h4 class="card-title">Actions</h4>
                <button class="btn btn-dark mr-4" @click="generateFinancialSheet"><i class="fa fa-file-download"></i>
                  Generate Financial Sheet For Selected Drivers
                </button>
                <b-dropdown right text="Download Financial Sheet For Selected Drivers" variant="info">
                  <template v-slot:button-content>
                    <i class="fa fa-file-download"></i>
                    Download Financial Sheet For Selected Drivers
                  </template>
                  <b-dropdown-item @click="downloadFinancialSheetForDriver('csv')">CSV</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="downloadFinancialSheetForDriver('pdf')">PDF</b-dropdown-item>
                </b-dropdown>
                <!--                <button class="btn btn-info" @click="downloadFinancialSheetForDriver"><i-->
                <!--                    class="fa fa-file-download"></i> Download Financial Sheet For Selected Drivers-->
                <!--                </button>-->
              </b-card>


              <!--           Remaining content here -------------   -->

              <template v-if="bSort && rSort">

                <b-card>
                  <h4 class="card-title"> Summary </h4>
                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{ bSort['name'] }} Soort</span></td>
                      <td>{{ bSort['cost_description'] }}</td>
                      <td class="text-right"><span class="font-15 font-weight-bold" id="popover-1">{{ bSort['name'] }} Total = {{
                          bSort['b17sTotal'] | toCurrencyFormat
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="text-danger">{{ rSort['name'] }} Soort</span></td>
                      <td>{{ rSort['cost_description'] }}</td>
                      <td class="text-right"><span class="font-15 font-weight-bold" id="popover-2">{{ rSort['name'] }} Total = {{
                          rSort['costSubTotal'] | toCurrencyFormat
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="text-danger">{{ rSort['name'] }} Soort</span></td>
                      <td>{{ rSort['income_description'] }}</td>
                      <td class="text-right"><span class="font-15 font-weight-bold" id="popover-3">{{ rSort['name'] }} Total = {{
                          rSort['incomeSubTotal'] | toCurrencyFormat
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="text-danger">Grand Total</span></td>
                      <td></td>
                      <td class="text-right"><span class="font-15 font-weight-bold"
                                                   id="popover-4"> Total = {{ grandTotal | toCurrencyFormat }}</span>
                      </td>
                    </tr>
                    <!-- popovers-->
                    <b-popover target="popover-1" triggers="hover" placement="auto">
                      <template #title>BreakDown</template>
                      <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                        <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                          {{ data['bSort']['b17sTotal'] | toCurrencyFormat }}
                        </div>
                      </div>
                      <button @click="downloadData('bSort')" class="btn btn-info btn-sm">Download data</button>
                    </b-popover>


                    <b-popover target="popover-2" triggers="hover" placement="auto">
                      <template #title>BreakDown</template>

                      <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                        <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                          {{ data['rSort']['costSubTotal'] | toCurrencyFormat }}
                        </div>
                      </div>
                      <button @click="downloadData('rSortCost')" class="btn btn-info btn-sm">Download data</button>

                    </b-popover>

                    <b-popover target="popover-3" triggers="hover" placement="auto">
                      <template #title>BreakDown</template>

                      <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                        <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                          {{ data['rSort']['incomeSubTotal'] | toCurrencyFormat }}
                        </div>
                      </div>
                      <button @click="downloadData('rSortIncome')" class="btn btn-info btn-sm">Download data</button>
                    </b-popover>

                    <b-popover target="popover-4" triggers="hover" placement="auto">
                      <template #title>BreakDown</template>
                      <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                        <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                          {{ data['grandTotal'] | toCurrencyFormat }}
                        </div>
                      </div>
                    </b-popover>
                    <!-- popovers-->
                  </table>

                </b-card>

              </template>

              <template v-if="bSort">
                <b-card class="mt-2">
                  <h4 class="card-title">B Soort breakdown</h4>

                  <table class="table mt-2 table-striped">
                    <thead>
                    <tr>
                      <th class="text-left">CDGRBREKENING</th>
                      <th>OMSCHR</th>
                      <th class="text-right">Bedrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(sort,ind) in bSort['17s']" :key="ind">
                      <th>{{ sort['CDGRBREKENING'] }}</th>
                      <th>{{ sort['OMSCHR'] }}</th>
                      <th class="text-right"><span
                          :id="'b-17s-popover-'+ind">{{ sort['Bedrag'] | toCurrencyFormat }}</span></th>
                      <b-popover :target="'b-17s-popover-'+ind" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ sort['CDGRBREKENING'] }}</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['bSort']['b17s'][ind]['Bedrag'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>

                    <tr>
                      <td colspan="3" class="text-right">
                        <span id="17sTotal">belasting en premies sociale |verzekeringen Total: {{
                            bSort['b17sTotal'] | toCurrencyFormat
                          }}</span>
                      </td>
                    </tr>

                    <tr v-for="(sort,ind) in bSort['02s']">
                      <th>{{ sort['CDGRBREKENING'] }}</th>
                      <th>{{ sort['OMSCHR'] }}</th>
                      <th class="text-right"><span
                          :id="'b-02s-data-popover-'+ind">{{ sort['Bedrag'] | toCurrencyFormat }}</span></th>
                      <b-popover :target="'b-02s-data-popover-'+ind" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ sort['CDGRBREKENING'] }}</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['bSort']['b02s'][ind]['Bedrag'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>

                    <tr>
                      <td colspan="3" class="text-right">
                        <span id="02sTotal">overige effecten Total: {{
                            bSort['b02sTotal'] | toCurrencyFormat
                          }}</span>
                      </td>
                    </tr>

                    <tr v-for="(sort,ind) in bSort['14s']">
                      <th>{{ sort['CDGRBREKENING'] }}</th>
                      <th>{{ sort['OMSCHR'] }}</th>
                      <th class="text-right"><span
                          :id="'b-14s-data-popover-'+ind">{{ sort['Bedrag'] | toCurrencyFormat }}</span></th>
                      <b-popover :target="'b-14s-data-popover-'+ind" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ sort['CDGRBREKENING'] }}</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['bSort']['b14s'][ind]['Bedrag'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>

                    <tr>
                      <td colspan="3" class="text-right">
                        <span id="1400">overlopende activa Total: {{ bSort['b14sTotal'] | toCurrencyFormat }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="text-right">
                        <span id="b-subTotal">subTotal: {{ bSort['subTotal'] | toCurrencyFormat }}</span>
                      </td>
                      <b-popover target="b-subTotal" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">SubTotal</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['bSort']['subTotal'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>
                    </tbody>
                  </table>
                  <b-card>
                    <button class="btn btn-outline-dark">Generate Bedrijfsvermogen Red Cabs</button>
                  </b-card>
                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">0650</span></td>
                      <td>Bedrijfsvermogen Red Cabs</td>
                      <td class="text-right"><span
                          class="font-15 font-weight-bold">{{ '0.00' | toCurrencyFormat }}</span></td>
                    </tr>
                  </table>

                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{ bSort['name'] }} Soort</span></td>
                      <td>{{ bSort['description'] }}</td>
                      <td class="text-right"><span class="font-15 font-weight-bold"
                                                   id="b-sort-sub-total">{{
                          bSort['name']
                        }} Total = {{ bSort['subTotal'] | toCurrencyFormat }}</span>
                      </td>
                      <b-popover target="b-sort-sub-total" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ bSort['name'] }} Total</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['bSort']['subTotal'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>
                  </table>
                </b-card>
              </template>

              <template v-if="rSort">
                <b-card class="mt-2">
                  <h4 class="card-title">R Soort breakdown</h4>
                  <span class="text-danger">{{ rSort['cost_description'] }}</span>
                  <table class="table mt-2 table-striped">
                    <thead>
                    <tr>
                      <th class="text-left">CDGRBREKENING</th>
                      <th>OMSCHR</th>
                      <th class="text-right">Bedrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(sort,ind) in rSort['costData']">
                      <th>{{ sort['CDGRBREKENING'] }}</th>
                      <th>{{ sort['OMSCHR'] }}</th>
                      <th class="text-right"><span
                          :id="'r-cost-data-popover-'+ind">{{ sort['Bedrag'] | toCurrencyFormat }}</span></th>
                      <b-popover :target="'r-cost-data-popover-'+ind" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ sort['CDGRBREKENING'] }}</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['rSort']['costData'][ind]['Bedrag'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>

                    </tbody>
                  </table>

                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{ rSort['name'] }} Soort</span></td>
                      <td>{{ rSort['cost_description'] }} Total</td>
                      <td class="text-right"><span class="font-15 font-weight-bold" id="r-subTotal">{{ rSort['name'] }} Total = {{
                          rSort['costSubTotal'] | toCurrencyFormat
                        }}</span>
                      </td>
                      <b-popover target="r-subTotal" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ rSort['name'] }} Total</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['rSort']['costSubTotal'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>
                  </table>

                </b-card>
                <b-card class="mt-2">
                  <h4 class="card-title">R Soort breakdown</h4>
                  <span class="text-danger">{{ rSort['income_description'] }}</span>
                  <table class="table mt-2 table-striped">
                    <thead>
                    <tr>
                      <th class="text-left">CDGRBREKENING</th>
                      <th>OMSCHR</th>
                      <th class="text-right">Bedrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(sort,ind) in rSort['incomeData']">
                      <th>{{ sort['CDGRBREKENING'] }}</th>
                      <th>{{ sort['OMSCHR'] }}</th>
                      <th class="text-right"><span
                          :id="'r-income-data-popover-'+ind">{{ sort['Bedrag'] | toCurrencyFormat }}</span></th>

                      <b-popover :target="'r-income-data-popover-'+ind" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ sort['CDGRBREKENING'] }}</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['rSort']['incomeData'][ind]['Bedrag'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{ rSort['name'] }} Soort</span></td>
                      <td>{{ rSort['income_description'] }} Total</td>
                      <td class="text-right"><span class="font-15 font-weight-bold"
                                                   id="r-income-sub-total">{{
                          rSort['name']
                        }} Total = {{ rSort['incomeSubTotal'] | toCurrencyFormat }}</span>
                      </td>
                      <b-popover target="r-income-sub-total" triggers="hover" placement="auto">
                        <template #title>BreakDown</template>
                        <h6 class="mt-0">{{ rSort['name'] }} Total</h6>
                        <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
                          <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
                            {{ data['rSort']['incomeSubTotal'] | toCurrencyFormat }}
                          </div>
                        </div>
                      </b-popover>
                    </tr>
                  </table>
                </b-card>
              </template>


            </template>
            <h3 class="text-center" v-if="driverList.length <1">Select one or more drivers to generate financial
              sheet</h3>
          </div>

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>

    <!--    popovers-->
    <b-popover v-if="bSort && rSort"
               :target="popOverTarget"
               :show.sync="popoverShow"
               placement="auto"
               container="my-container"
               ref="popover"
    >
      <template #title>
        <b-button class="close" aria-label="Close" @click="popClose">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Breakdown
      </template>
      <div v-for="(data,i) in outputDetails" :key="i" class="mb-1">
        <div>{{ data['driver']['name'] }} {{ data['driver']['other_names'] }} :
          {{ data['bSort']['subTotal'] | toCurrencyFormat }}
        </div>
      </div>
    </b-popover>


    <!--    end of popovers-->
  </div>
</template>

<script>

import Sidepanel from "./f-sheet-sidepanel";
import DatePicker from "vue2-datepicker";

import {financialSheetService} from "../../../../apis/finanical.sheet.service";

export default {
  name: "generator",
  components: {
    Sidepanel,
    DatePicker,

  },
  data() {
    return {
      download_link: '',
      popoverShow: false,
      popOverTarget: 'popover-1',
      selectedOption: 'filter-by-date-range',
      startWeek: '',
      periods: [],
      endWeek: '',
      date_range: [],
      driverList: [],
      outputDetails: [],
      bSort: null,
      rSort: null,
      grandTotal: null,
      driver: null,
      financialData: [],
      filterOptions: [
        {value: null, text: '-- Please select a filter --'},
        {value: 'first-quarter', text: 'First quarter'},
        {value: 'second-quarter', text: 'Second quarter'},
        {value: 'third-quarter', text: 'Third quarter'},
        {value: 'forth-quarter', text: 'Forth quarter'},
        {value: 'full-year', text: 'Full year'},
        {value: 'clear', text: 'Clear all'}
      ],
      filterSelected: null,
      years: [],
      selectedYear: null,
      downloadOption: 'merge',
      usePeriods: false
    }
  },

  methods: {
    selectFilterCard() {
      this.usePeriods = false;
      if (this.selectedOption === 'filter-by-date-range') {
        this.startWeek = '';
        this.endWeek = '';
      } else if (this.selectedOption === 'filter-by-weeks') {
        this.date_range = [];
      } else if (this.selectedOption === 'filter-by-periods') {
        this.usePeriods = true;
        this.startWeek = '';
        this.endWeek = '';
        this.date_range = [];
      }

    },
    popOpen(trigger) {
      this.trigger = trigger;
      this.popoverShow = true;
    },
    popClose() {
      this.popoverShow = false;
    },
    removeDate() {
      this.date_range = [];
    },
    downloadData(type) {
      this.$store.dispatch('showLoader')
      financialSheetService.downloadFinancialComponent({type, data: this.financialData}).then((data) => {
        const {extra} = data
        location.href = this.getFinancialComponentUrl(extra);
      }).finally(() => {
        this.$store.dispatch('hideLoader')
      });

    },

    dateRangeChanged() {
      // console.log(this.date_range,'date_range');
      this.usePeriods = false;
      // this.generateFinancialSheet();
    },

    onFilterPeriodsChanged() {
      console.log('selected', this.filterSelected)
      if (this.filterSelected === 'first-quarter') {
        this.periods.push(1, 2, 3)
        this.sortPeriods();
      }
      if (this.filterSelected === 'second-quarter') {
        this.periods.push(4, 5, 6)
        this.sortPeriods();
      }
      if (this.filterSelected === 'third-quarter') {
        this.periods.push(7, 8, 9)
        this.sortPeriods();
      }
      if (this.filterSelected === 'forth-quarter') {
        this.periods.push(10, 11, 12)
        this.sortPeriods();
      }
      if (this.filterSelected === 'full-year') {
        this.addAllPeriods();
        this.sortPeriods();
      }
      if (this.filterSelected === 'clear') {
        this.removeAllPeriods();
      }

      this.filterSelected = null
    },

    sortPeriods() {
      this.periods.sort(function (a, b) {
        return a - b;
      });
    },

    periodTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)) {
          return false
        } else if (!Number.isInteger(num)) {
          return false
        } else if (num < 1 || num > 12) {
          return false
        } else {
          return true;
        }
      } catch (e) {
      }

    },
    addAllPeriods() {
      this.periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },

    removeAllPeriods() {
      this.periods = [];
    },

    getFinancialComponentUrl(token) {
      return process.env.VUE_APP_API_BASE_URL + '/download-financial-component/' + token
    },

    getDownloadUrl(token) {
      return process.env.VUE_APP_API_BASE_URL + '/download-financial-sheet/' + token
    },

    weekChanged() {
      this.usePeriods = false
    },

    downloadFinancialSheetForDriver(type) {
      if (this.driverList.length < 1) {
        this.$store.dispatch('error', {
          message: 'Please select drivers from the side-panel to proceed.',
          showSwal: true
        })
        return;
      }
      console.log(this.driverList, 'driverList')
      const payload = {
        "startWeek": this.startWeek,
        "endWeek": this.endWeek,
        'driverIds': this.driverList.map((e) => e.id),
        'periods': this.periods,
        'year': this.selectedYear,
        'usePeriods': this.usePeriods,
        'date_range': this.date_range,
        'downloadOption': this.downloadOption,
        'type': type
      }

      this.$store.dispatch('downloadFinancialSheet', payload).then((token) => {
        location.href = this.getDownloadUrl(token);
      });
    },

    onDriverSelected(drivers, selectAll) {
      if (selectAll) {
        this.driverList = drivers;
      } else {
        if (!drivers) {
          return;
        }
        // this.driver = driver;
        let ind = this.driverList.indexOf(drivers);
        if (ind > -1) {
          this.driverList.splice(ind, 1);
        } else {
          this.driverList.push(drivers);
        }
        this.driverList = [...new Map(this.driverList.map(item => [item.id, item])).values()];
      }
    },

    generateFinancialSheet() {

      if (this.driverList.length < 1) {
        this.$store.dispatch('error', {
          message: 'Please select drivers from the side-panel to proceed.',
          showSwal: true
        })
        return;
      }
      const payload = {
        'start-week': this.startWeek,
        'end-week': this.endWeek,
        'periods': this.periods,
        'usePeriods': this.usePeriods,
        'year': this.selectedYear,
        'date_range': this.date_range,
        'drivers': this.driverList
      }
      this.$store.dispatch('generateFinancialSheet', payload).then((data) => {
        this.financialData = data;
        this.startWeek = data['startWeek']
        this.endWeek = data['endWeek']
        this.bSort = data['output']['bSort']
        this.rSort = data['output']['rSort']
        this.grandTotal = data['output']['grandTotal']
        this.outputDetails = data['output_details'];
      })
    }
  },
  watch: {
    driver: function (driver) {
      // if(driver){
      //
      // }
      // this.generateFinancialSheet(this.usePeriods)
    },
    usePeriods: function (value) {
      if (!value) {
        this.removeAllPeriods();
      }
    },
    '$store.getters.GET_AVAILABLE_YEARS': function (ys) {
      this.years = ys;
      if (this.years.length > 1) {
        this.selectedYear = this.years[0]
      }

    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Drivers financial sheet')
    this.$store.dispatch('getAvailableYears');
  },

  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if (data.title === 'processing_financial_sheet') {
        this.$store.dispatch('setProcessingInfo', event.heading);
      }
    });
  }
}
</script>

<style scoped>
.line-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.popover {
  max-height: 400px;
  overflow-y: scroll;
}
</style>
